import {
  colors,
  IconCross10,
  Link,
  LittersAddHeader,
  Wrapper,
  Form,
  Alert,
  PageLoading,
  LittersAddConfirm,
  IInputDescriptionValues,
  Fieldset,
  ColFormWrapper,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { RouteComponentProps, Redirect } from "@reach/router";
import { parse } from "query-string";
import { CheckPermission } from "../../components/CheckPermission";
import { useLitter, useLitterUpdate } from "../../lib/hooks/useLitters";
import {
  FormLitterAvailableInputs,
  getInitialValues as getInitialValuesAvailableInputs,
  IFormLitterAdAvailableValues,
} from "../../components/FormLitterAvailableInputs";
import {
  FormLitterAdInputs,
  getInitialValues as getInitialValuesAdInputs,
  IFormLitterAdInfoValues,
  messages as messagesAdInputs,
  getValidationDateVisibleMinDate,
  getValidationDateVisibleMaxDate,
} from "../../components/FormLitterAdInputs";
import { object, string, number } from "yup";
import { messages as messagesBaseInfo } from "../../components/FormLitterBaseInfo";
import { pick } from "lodash";
import { withPage } from "../../lib/withPage";
import { startOfDay } from "date-fns/esm";
import { useAmagingUploadTokenForLitter } from "../../lib/hooks/useAmagingUploadToken";
import { useCurrentPartnerDetails } from "../../lib/hooks/usePartnerDetails";
import {
  FormLitterDetailsInputs,
  getInitialValues as getInitialValuesDetailsInputs,
  IFormLitterDetailsValues,
  getValidation as getValidationDetailsInputs,
} from "../../components/FormLitterDetailsInputs";

type IStep = "form" | "confirm";

type IFormValues = IFormLitterAdAvailableValues &
  IFormLitterAdInfoValues &
  IFormLitterDetailsValues;

const LitterEdit = ({ location }: RouteComponentProps) => {
  const { formatMessage } = useIntl();
  const { id } = parse(location?.search || "");
  const [step, setStep] = useState<IStep>("form");
  const [error, setError] = useState<{ message: string }>();
  const { litterUpdate } = useLitterUpdate();
  const { getAmagingUploadToken } = useAmagingUploadTokenForLitter();

  const { data: partner, loading: loadingPartner } = useCurrentPartnerDetails();
  const { item: litter, loading } = useLitter({
    id: (id as string) || undefined,
  });
  if (!id) {
    return <Redirect to="litters" />;
  }

  if (loading || loadingPartner) {
    return <PageLoading />;
  }

  if (!litter || (partner && !partner.isConv)) {
    return <Redirect to="litters" noThrow />;
  }

  const isValidDateVisible = (value: any): boolean => {
    const val = startOfDay(value ? new Date(value) : new Date());
    const minDate = startOfDay(
      getValidationDateVisibleMinDate(litter.dob || litter.createdAt),
    );
    const maxDate = startOfDay(litter.dob ? getValidationDateVisibleMaxDate(litter.dob) : new Date());
    return val >= minDate && val <= maxDate;
  };

  return (
    <Wrapper>
      {!!error && <Alert type="error">{error.message}</Alert>}

      {step === "form" && (
        <Row center="xs">
          <ColFormWrapper xs={12} sm={12} md={12} lg={8}>
            <Form<IFormValues & IInputDescriptionValues>
              loading={loading}
              initialValues={{
                ...litter,
                ...getInitialValuesAvailableInputs(litter),
                ...getInitialValuesAdInputs({
                  ...litter,
                  photos: litter.photos.map((photo) => ({
                    id: photo.id,
                    preview: photo.url,
                  })),
                }),
                ...getInitialValuesDetailsInputs(litter),
              }}
              schema={object().shape({
                dateAvailable: string()
                  .nullable()
                  .required(
                    formatMessage(
                      messagesBaseInfo.formErrorInvalidDateAvailable,
                    ),
                  ),
                availableMale: number()
                  .typeError(formatMessage(messagesBaseInfo.formErrorNumber))
                  .integer(formatMessage(messagesBaseInfo.formErrorNumber))
                  .min(0)
                  .max(
                    (litter?.male || 0) +
                      (litter?.genderUnspecifiedCount || 0) || 10,
                  )
                  .required(),
                availableFemale: number()
                  .typeError(formatMessage(messagesBaseInfo.formErrorNumber))
                  .integer(formatMessage(messagesBaseInfo.formErrorNumber))
                  .min(0)
                  .max(
                    (litter?.female || 0) +
                      (litter?.genderUnspecifiedCount || 0) || 10,
                  )
                  .required(),
                dateVisibleFrom: string()
                  .test(
                    "isValidDateVisible",
                    formatMessage(messagesAdInputs.formErrorDateVisibleInvalid),
                    isValidDateVisible,
                  )
                  .nullable()
                  .required(
                    formatMessage(messagesAdInputs.formErrorDateVisibleInvalid),
                  ),
                dateVisibleTo: string()
                  .test(
                    "isValidDateVisible",
                    formatMessage(messagesAdInputs.formErrorDateVisibleInvalid),
                    isValidDateVisible,
                  )
                  .nullable()
                  .required(
                    formatMessage(messagesAdInputs.formErrorDateVisibleInvalid),
                  ),
                ...getValidationDetailsInputs({ intl: { formatMessage } }),
              })}
              onSubmit={async ({
                descriptionByLang,
                photos,
                otherBreeding,
                ...values
              }: IFormValues & IInputDescriptionValues) => {
                const photosData = (
                  await Promise.all(
                    photos.map(async (photo, i) => {
                      if (!photo.file) {
                        return {
                          id: photo.id as number,
                          url: photo.preview as string,
                        };
                      }

                      const {
                        token: amagingUploadToken,
                      } = await getAmagingUploadToken(litter.id, i);
                      if (!amagingUploadToken) {
                        return;
                      }

                      await fetch(amagingUploadToken.url, {
                        method: "post",
                        body: photo.file,
                        headers: {
                          Accept: "application/json",
                          Authorization: "Bearer " + amagingUploadToken.token,
                          "Content-Lenght": photo.file.size.toString(),
                          "Content-Type": photo.file.type,
                        },
                      });

                      return {
                        id: -1,
                        url: amagingUploadToken.urlPreview,
                      };
                    }),
                  )
                ).filter(Boolean) as {
                  id: number;
                  url: string;
                }[];

                const { error: errorLitterCreate } = await litterUpdate(
                  id as string,
                  {
                    ...pick(values, [
                      "dateAvailable",
                      "availableMale",
                      "availableFemale",
                      "dateVisibleFrom",
                      "dateVisibleTo",
                      "neutered",
                      "chippedTatooed",
                      "vaccinated",
                      "identification",
                      "royalStartSyncAvailable",
                    ]),
                    pureBreed: values.pureBreed || false,
                    descriptions: Object.keys(descriptionByLang).map(
                      (language) => ({
                        language,
                        value: descriptionByLang[language],
                      }),
                    ),
                    photos: photosData.map((photo) => ({
                      ...photo,
                      id: photo.id.toString(),
                    })),
                    pedigreeFCI: values.pureBreed ? values.pedigreeFCI : false,
                    idFather: values.pureBreed ? values.idFather || null : null,
                    idMother: values.idMother || null,
                    originCountry: otherBreeding
                      ? values.originCountry || null
                      : null,
                    originBreeder: otherBreeding
                      ? values.originBreeder || null
                      : null,
                    startingPrice: values.startingPrice
                      ? values.startingPrice.toString()
                      : null,
                    maxPrice: values.maxPrice
                      ? values.maxPrice.toString()
                      : null,
                  },
                );
                if (errorLitterCreate) {
                  setError(errorLitterCreate);
                } else {
                  setError(undefined);
                  setStep("confirm");
                }
              }}
              render={({ handleSubmit, submitError, submitting, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {submitError && (
                      <div style={{ marginBottom: 30 }}>
                        <Alert type="error">{submitError}</Alert>
                      </div>
                    )}
                    <LittersAddHeader
                      backLink={
                        <Link
                          href="/litters"
                          borderless
                          prefix={
                            <IconCross10
                              width={10}
                              height={20}
                              fill={colors.primary}
                            />
                          }
                        >
                          <FormattedMessage
                            id="button.label.cancel"
                            defaultMessage="Annuler"
                          />
                        </Link>
                      }
                      submitDisabled={submitting}
                    >
                      <FormattedMessage
                        id="website.litters.edit.title"
                        defaultMessage="Éditer une portée"
                      />
                    </LittersAddHeader>

                    <FormLitterAvailableInputs values={litter || {}} />
                    <Fieldset offset={10}>
                      <FormLitterAdInputs
                        minDateVisibleFrom={litter.createdAt}
                        values={values}
                      />
                    </Fieldset>
                    <Fieldset offset={10}>
                      <FormLitterDetailsInputs values={values} />
                    </Fieldset>
                  </form>
                );
              }}
            />
          </ColFormWrapper>
        </Row>
      )}

      {step === "confirm" && <LitterEditConfirm values={litter || {}} />}
    </Wrapper>
  );
};

const LitterEditPage = (props: RouteComponentProps) => (
  <Layout>
    <CheckPermission permissions={"litters"} redirect>
      <LitterEdit {...props} />
    </CheckPermission>
  </Layout>
);

export default withPage(LitterEditPage);

const LitterEditConfirm = ({ values }: { values: IFormValues }) => (
  <LittersAddConfirm
    title={
      <FormattedMessage
        id="website.litters.confirm.editLitter"
        defaultMessage="Votre portée a bien été enregistrée."
      />
    }
    text={false}
    adDateStart={values.dateVisibleFrom || undefined}
    adDateEnd={values.dateVisibleTo || undefined}
  />
);
